import React, {Component} from 'react';

class MainMenu extends Component {

    constructor() {
        console.log("menu constructor");
        super();
    }

    render() {
      return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">Andrey Hurpia da Rocha</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="home" href="/">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" aria-current="posts" href="/">Posts</a>
                </li>
              </ul>
            </div>
          </div>
        </nav> )
      }
}

export default MainMenu;